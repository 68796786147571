import { ImageWithConfigFragment } from '@codegen/cmsUtils';
import Box from '@ui-v2/core/Box/Box';
import Image from '@ui-v2/core/Image/Image';
import Text from '@ui-v2/core/Text/Text';

export type FooterItemContentProps = {
  image?: ImageWithConfigFragment | null;
  label?: string;
};

const FooterItemContent = ({ image, label }: FooterItemContentProps) => {
  if (label && !image) {
    return label;
  }

  return (
    <Box alignItems="center" display="flex" flexDirection="column">
      {label && <Text>{label}</Text>}
      {image && (
        <Image
          alt={label}
          height={image.asset.height}
          src={image.asset.url}
          width={image.asset.width}
        />
      )}
    </Box>
  );
};

export default FooterItemContent;
