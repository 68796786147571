import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Footer from '@web/components/Footer/Footer';
import HeaderContainer from './HeaderContainer';
import MaintainceBannerContainer from './MaintainceBannerContainer';

const StyledLayoutGrid = styled.div`
  display: grid;
  min-height: 100vh;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
`;

const Container = styled.div(
  ({ theme: { colours } }) => css`
    position: relative;
    background: ${colours.background.main};
  `,
);

const PageLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <StyledLayoutGrid>
      <div>
        <MaintainceBannerContainer />
        <HeaderContainer />
      </div>
      <Container>{children}</Container>
      <Footer />
    </StyledLayoutGrid>
  );
};

export default PageLayout;
